import { css } from 'styled-components'

export const linkStyles = css`
  text-decoration: none;
  color: #0078d6;
  transition: color linear 0.2s;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #0045a3;
  }
`
