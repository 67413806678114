import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from 'styled-components'


const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: 'Montserrat', sans-serif; 
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  * {
      box-sizing: border-box;
  }
`

const Main = styled.main`
  margin: auto;
  max-width: 1280px;
`

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Main>{children}</Main>
  </>
)


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
