import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

export const TEXT_TYPE = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  REGULAR: 'p',
}

export const TEXT_WEIGHT = {
  BOLD: 'bold',
  NORMAL: 'normal',
}

export const TEXT_ALIGN = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
}

export const TEXT_COLOR = {
  PRIMARY: '#000000',
  ALTERNATE: '#0078D6',
}

export const TEXT_TRANSFORM = {
  CAPITALIZE: 'capitalize',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  NONE: 'none',
}

const setTextType = ({ textType }) => {
  const result = [
    {
      [TEXT_TYPE.REGULAR]: css`
        font-size: 14px;
        margin: 0;
        line-height: 1.5;
      `,
      [TEXT_TYPE.H1]: css`
        font-size: 48px;
        font-weight: bold;
        line-height: 1.3;
      `,
      [TEXT_TYPE.H2]: css`
        font-size: 26px;
        font-weight: bold;
        line-height: 1.3;
      `,
      [TEXT_TYPE.H3]: css`
        font-size: 18px;
        font-weight: bold;
        line-height: 1.3;
      `,
    }[textType],
  ].filter(Boolean)

  return result
}

const StyledText = styled.p`
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-weight: ${({ weight }) => weight};
  text-transform: ${({ transform }) => transform};
  ${setTextType};
`

const Text = ({
  children,
  textType,
  align,
  weight,
  color,
  transform,
  ...restProps
}) => {
  return (
    <StyledText
      color={color}
      align={align}
      as={textType}
      weight={weight}
      textType={textType}
      transform={transform}
      {...restProps}
    >
      {children}
    </StyledText>
  )
}

Text.defaultProps = {
  textType: TEXT_TYPE.REGULAR,
  weight: TEXT_WEIGHT.NORMAL,
  align: TEXT_ALIGN.LEFT,
  color: TEXT_COLOR.PRIMARY,
  transform: TEXT_TRANSFORM.NONE,
}

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  textType: PropTypes.oneOf([
    TEXT_TYPE.H1,
    TEXT_TYPE.H2,
    TEXT_TYPE.H3,
    TEXT_TYPE.REGULAR,
  ]),
  weight: PropTypes.oneOf([TEXT_WEIGHT.NORMAL, TEXT_WEIGHT.BOLD]),
  align: PropTypes.oneOf([
    TEXT_ALIGN.LEFT,
    TEXT_ALIGN.RIGHT,
    TEXT_ALIGN.CENTER,
  ]),
  transform: PropTypes.oneOf([
    TEXT_TRANSFORM.CAPITALIZE,
    TEXT_TRANSFORM.UPPERCASE,
    TEXT_TRANSFORM.LOWERCASE,
    TEXT_TRANSFORM.NONE,
  ]),
}

export default Text
