import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledListItem = styled.li`
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
`

const ListItem = ({ children }) => <StyledListItem>{children}</StyledListItem>

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ListItem
