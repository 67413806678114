import React from 'react'
import PropTypes from 'prop-types'

export const MARKER_TYPE = {
  NUMBERS: '1',
  UPPERCASE_ALPHABET: 'A',
  LOWERCASE_ALPHABET: 'a',
  UPPERCASE_ROMANIAN: 'I',
  LOWERCASE_ROMANIAN: 'i',
}

const OrderedList = ({ children, markerType }) => (
  <ol type={markerType}>{children}</ol>
)

OrderedList.defaultProps = {
  markerType: MARKER_TYPE.NUMBERS,
}

OrderedList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  markerType: PropTypes.oneOf([
    MARKER_TYPE.NUMBERS,
    MARKER_TYPE.UPPERCASE_ALPHABET,
    MARKER_TYPE.LOWERCASE_ALPHABET,
    MARKER_TYPE.UPPERCASE_ROMANIAN,
    MARKER_TYPE.LOWERCASE_ROMANIAN,
  ]),
}

export default OrderedList
