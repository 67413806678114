import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { linkStyles } from './styles'

const StyledAnchor = styled(Link)`
  ${linkStyles}
`

const StyledLink = ({ children, ...props }) => (
  <StyledAnchor {...props}>{children}</StyledAnchor>
)

export default StyledLink
