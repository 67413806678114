import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Footer from '../features/Footer'
import Layout from '../components/Layout'
import Logo from '../components/Logo'
import Text, { TEXT_TYPE } from '../components/Typography'
import { StyledLink } from '../components/Link'

const TextWrapper = styled.section`
  width: 50%;
  margin: 0 auto;
`

const TextSection = styled.section`
  margin-bottom: 25px;
`

const StyledTable = styled.table`
  margin-top: 50px;
  border-spacing: 0;
  border-collapse: initial;
  border-right: 1px solid #8a8a8a;
  border-bottom: 1px solid #8a8a8a;
`
const StyledCell = styled.td`
  border-top: 1px solid #8a8a8a;
  border-left: 1px solid #8a8a8a;
  font-size: 16px;
  padding: 15px 15px 15px 20px;
  vertical-align: middle;
`

const PrivacyPolicy = ({ data }) => {
  const title = data.markdownRemark.frontmatter.title
  const html = data.markdownRemark.html

  return (
    <Layout>
      <Logo />
      <TextWrapper>
        <TextSection>
          <p>
            <StyledLink to="/">Back to home</StyledLink>
          </p>
          <Text textType={TEXT_TYPE.H1}>{title}</Text>
          <Text>Last updated on 29 May 2019</Text>
        </TextSection>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <StyledTable>
          <thead>
            <tr>
              <StyledCell>Cookie name</StyledCell>
              <StyledCell>What it’s for</StyledCell>
            </tr>
          </thead>
          <tbody>
            <tr>
              <StyledCell>__cfduid</StyledCell>
              <StyledCell>
                We use Cloudflare content distribution network. They use it to
                identify individual clients behind a share IP address and apply
                security setting on a per client basis.
              </StyledCell>
            </tr>
            <tr>
              <StyledCell>__ga</StyledCell>
              <StyledCell>
                We use Google Analytics, this cookie is used to distinguish
                users.
              </StyledCell>
            </tr>
            <tr>
              <StyledCell>__gid</StyledCell>
              <StyledCell>
                We use Google Analytics, this cookie is used to distinguish
                users.
              </StyledCell>
            </tr>
            <tr>
              <StyledCell>__gat</StyledCell>
              <StyledCell>
                We use Google Analytics, this cookie is used to throttle
                requests.
              </StyledCell>
            </tr>
          </tbody>
        </StyledTable>
      </TextWrapper>
      <Footer />
    </Layout>
  )
}

export default PrivacyPolicy

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
