import React from 'react'
import styled from 'styled-components'
import { StyledA, StyledLink } from '../components/Link'

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 35px 190px 45px;
  justify-content: space-between;
`

const Link = styled(StyledLink)`
  margin-right: 10px;
`

const Footer = () => (
  <StyledFooter>
    <StyledA href="mailto:familyfriendlydns@gmail.com">
      familyfriendlydns@gmail.com
    </StyledA>
    <div>
      <Link to="/cookies-policy">Cookies Policy</Link>
      <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
    </div>
  </StyledFooter>
)

export default Footer
